import React, { useState, useEffect } from "react";
import "./navbar.css";
import logo from "../../assets/logo.png";
import menu from "../../assets/menu.png";
import { Link } from "react-scroll";
import contactImg from "../../assets/contact.png";
import { debounce } from "lodash";

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['intro', 'skills', 'works', 'photos', 'contact'];
      let currentSection = '';
  
      for (const section of sections) {
        const el = document.getElementById(section);
        if (el) {
          const scrollPosition = window.scrollY + window.innerHeight / 2; // Middle of the screen
          const sectionTop = el.offsetTop;
          const sectionHeight = el.offsetHeight;
  
          if (scrollPosition >= sectionTop && scrollPosition <= sectionTop + sectionHeight) {
            currentSection = section;
            break;
          }
        }
      }
  
      setActiveSection(currentSection);
  
      if (window.scrollY > 80) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
  
      if (showMenu) {
        setShowMenu(false);
      }
    };
  
    // Debounce the handleScroll function to improve performance
    const debouncedHandleScroll = debounce(handleScroll, 100);
  
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  }, [showMenu]);
  
  useEffect(() => {
    const closeMenu = (e) => {
      if (!e.target.closest(".navMenu") && !e.target.closest(".mobMenu") && showMenu) {
        setShowMenu(false);
      }
    };

    document.addEventListener("click", closeMenu);
    return () => document.removeEventListener("click", closeMenu);
  }, [showMenu]);

  return (
    <nav className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <Link
        to="intro"
        spy={true}
        smooth={true}
        duration={500}
        className="logoLink"
      >
        <button className="btnLogo">
          <img src={logo} alt="Logo" className="logo" />
        </button>
      </Link>

      <div className="desktopMenu">
        <Link
          to="intro"
          spy={true}
          smooth={true}
          duration={500}
          className={`desktopMenuListItem ${activeSection === 'intro' ? 'active' : ''}`}
          offset={-100}
        >
          Home
        </Link>
        <Link
          to="skills"
          spy={true}
          smooth={true}
          duration={500}
          className={`desktopMenuListItem ${activeSection === 'skills' ? 'active' : ''}`}
          offset={-50}
        >
          About
        </Link>
        <Link
          to="works"
          spy={true}
          smooth={true}
          duration={500}
          className={`desktopMenuListItem ${activeSection === 'works' ? 'active' : ''}`}
          offset={-50}
        >
          Portfolio
        </Link>
        <Link
          to="photos"
          spy={true}
          smooth={true}
          duration={500}
          className={`desktopMenuListItem ${activeSection === 'photos' ? 'active' : ''}`}
          offset={-50}
        >
          Photography
        </Link>
      </div>

      <button
        className="desktopMenuBtn"
        onClick={() => {
          const contactSection = document.getElementById("contact");
          if (contactSection) {
            const offset = 100; 
            const contactPosition = contactSection.offsetTop - offset;
            window.scrollTo({ top: contactPosition, behavior: "smooth" });
          }
        }}
      >
        <img src={contactImg} alt="" className="desktopMenuImg" />
        Contact Me
      </button>


      <img
        src={menu}
        alt="Menu"
        className="mobMenu"
        onClick={() => setShowMenu(!showMenu)}
      />
      <div className="navMenu" style={{ display: showMenu ? "flex" : "none" }}>
        <Link
          to="intro"
          spy={true}
          smooth={true}
          duration={500}
          className={`listItem ${activeSection === 'intro' ? 'active' : ''}`}
          offset={-100}
          onClick={() => setShowMenu(false)}
        >
          Home
        </Link>
        <Link
          to="skills"
          spy={true}
          smooth={true}
          duration={500}
          className={`listItem ${activeSection === 'skills' ? 'active' : ''}`}
          offset={-50}
          onClick={() => setShowMenu(false)}
        >
          About
        </Link>
        <Link
          to="works"
          spy={true}
          smooth={true}
          duration={500}
          className={`listItem ${activeSection === 'works' ? 'active' : ''}`}
          offset={-50}
          onClick={() => setShowMenu(false)}
        >
          Portfolio
        </Link>
        <Link
          to="photos"
          spy={true}
          smooth={true}
          duration={500}
          className={`listItem ${activeSection === 'photos' ? 'active' : ''}`}
          offset={-50}
          onClick={() => setShowMenu(false)}
        >
          Photography
        </Link>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          duration={500}
          className={`listItem ${activeSection === 'contact' ? 'active' : ''}`}
          offset={-60}
          onClick={() => setShowMenu(false)}
        >
          Contact
        </Link>
      </div>

    </nav>
  );
}

export default Navbar;
