import React from "react";
import "./works.css";
import HeroLogo from "../../assets/analytic-graph.png";
import MovieLogo from "../../assets/clapperboard.png";
import PlateLogo from "../../assets/dish.png";

const projects = [
  {
    title: "Hero Quest",
    description:
      "A mobile app that gamifies personal growth, making the journey of self-improvement an engaging role-playing game",
    link: "https://github.com/aroizmand/HeroQuest",
    img: HeroLogo,
    status: "Ongoing",
  },
  {
    title: "Pass The Plate",
    description:
      "A mobile app designed to combat food waste by facilitating the sharing of excess food within communities",
    link: "https://github.com/aroizmand/pass-the-plate",
    img: PlateLogo,
    status: "Finished",
  },
  {
    title: "Movie Analysis",
    description:
      "An in-depth exploratory project to uncover trends, preferences, and predictors in the world of cinema",
    link: "https://github.com/aroizmand/Movie-IMDB-Analysis",
    img: MovieLogo,
    status: "Finished",
  },
];

const Works = () => {
  return (
    <section id="works">
      <h2 className="worksTitle">Projects</h2>
      <span className="worksDesc">
        I believe in using innovation to improve how we live, work, and connect
        with one another, always with an eye towards a better and more
        compassionate society
      </span>
      <div className="worksImgs">
        {projects.map((project, index) => (
          <div key={index} className="projectCard">
            <a href={project.link} target="_blank" rel="noreferrer">
              <div className="projectInfo">
                <img alt="project icon" className="icon" src={project.img} />
                <div className="projectTitle">{project.title}</div>
                <p className="projectDesc">{project.description}</p>
                <div
                  className={`statusDot ${
                    project.status === "Ongoing" ? "ongoing" : "finished"
                  }`}
                ></div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Works;
