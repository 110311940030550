import React from "react";
import "./skills.css";
import Software from "../../assets/code.png";
import Data from "../../assets/data.png";
import Photo from "../../assets/photo.png";

const Skills = () => {
  return (
    <section id="skills">
      <span className="skillTitle">What I do</span>
      <span className="skillDesc">
        I'm passionate about using technology to solve real-world problems. I specialize in software development, data science, and wildlife photography.
      </span>
      <div className="skillBars">
        <div className="skillBar">
          <img src={Software} alt="Coding" className="skillBarImg" />
          <div className="skillBarText software">
            <h2 className="skillBarTitle">Software Development</h2>
            <p>
              Pursuing a Computer Science major at The University of British
              Columbia
            </p>
            <div className="skillTags">
              <span className="skill">React Native</span>
              <span className="skill">Java</span>
              <span className="skill">ReactJS</span>
              <span className="skill">JavaScript</span>
              <span className="skill">PHP</span>
              <span className="skill">HTML</span>
              <span className="skill">CSS</span>
            </div>
          </div>
        </div>
        <div className="skillBar">
          <img src={Data} alt="Data Analysis" className="skillBarImg data" />
          <div className="skillBarText">
            <h2 className="skillBarTitle">Data Science</h2>
            <p>
              Completed an internship as a Data Analyst and pursuing a Data
              Science minor
            </p>
            <div className="skillTags">
              <span className="skill">Python</span>
              <span className="skill">SQL</span>
              <span className="skill">R</span>
              <span className="skill">Tableau</span>
              <span className="skill">Excel</span>
            </div>
          </div>
        </div>
        <div className="skillBar">
          <img src={Photo} alt="Photography" className="skillBarImg photo" />
          <div className="skillBarText">
            <h2 className="skillBarTitle">Wildlife Photography</h2>
            <p>
              Years of experience as a wildlife photographer in several
              countries
            </p>
            <div className="skillTags">
              <span className="skill">Lightroom</span>
              <span className="skill">Composition</span>
              <span className="skill">Storytelling</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
