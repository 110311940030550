import React, { useState, useEffect } from 'react';
import Navbar from './components/NavBar/navbar';
import MoreNavbar from './components/MoreNavbar/moreNavbar';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HashRouter as Router } from 'react-router-dom';
import Intro from './components/Intro/intro';
import Skills from './components/Skills/skills';
import Works from './components/Works/works';
import Contact from './components/Contact/contact';
import Photos from './components/Photos/photos';
import Footer from './components/Footer/footer';
import Gallery from './components/Gallery/gallery';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';

function Content() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const location = useLocation();
  const isMorePhotos = location.pathname === '/Gallery';
  const [init, setInit] = useState(false);


  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);
  
  

  const particlesOptions = {
    background: {
      color: {
        value: 'black',
      },
    },
    fpsLimit: 120,
    interactivity: {
      events: {
        onClick: {
          enable: false,
        },
        onHover: {
          enable: true,
          mode: 'repulse',
        },
      },
      modes: {
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 100,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: '#ffffff',
      },
      links: {
        color: '#ffffff',
        distance: 150,
        enable: true,
        opacity: 0.3,
        width: 1,
      },
      move: {
        direction: 'none',
        enable: true,
        outModes: {
          default: 'bounce',
        },
        random: false,
        speed: 3,
        straight: false,
      },
      number: {
        density: {
          enable: true,
        },
        value: 55,
      },
      opacity: {
        value: 0.2,
      },
      shape: {
        type: 'circle',
      },
      size: {
        value: { min: 1, max: 3 },
      },
    },
    detectRetina: true,
  };

  return (
    <div className={`App ${init ? '' : 'loading'}`}>
      <div className={`loading-screen ${init ? 'fade-out' : ''}`}>
        <div className="spinner-container">
          <div className="spinner">
            <div className="spinner">
              <div className="spinner">
                <div className="spinner">
                  <div className="spinner">
                    <div className="spinner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {init && (
        <>
          <Particles id="tsparticles" options={particlesOptions} />
          {isMorePhotos ? (
            <MoreNavbar onSelectCategory={setSelectedCategory} />
          ) : (
            <Navbar />
          )}
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Intro />
                  <Skills />
                  <Works />
                  <Photos />
                  <Contact />
                  <Footer />
                </>
              }
            />
            <Route
              path="/Gallery"
              element={
                <>
                  <Gallery selectedCategory={selectedCategory} />
                  <Footer />
                </>
              }
            />
          </Routes>
        </>
      )}
    </div>
  );
}  

function App() {

  return (
    <Router>
      <Content />
    </Router>
  );
}

export default App;
