import React from "react";
import "./intro.css";


const Intro = () => {
  return (
    <section id="intro">
      <div className="introContent">
        <span className="hello">Hi there,</span>
        <span className="introText">
          I'm <span className="introName">Alexander</span>
          <br />
          <span className="introTextCareer">Developer & Photographer</span>
        </span>
        <p className="introPara">
          with a passion for technological innovation and wildlife conservation.
        </p>
      </div>
    </section>
  );
};

export default Intro;
